import React from 'react';
import Layout from '../component/Layout';

const Products= () => {
    return (
        <Layout>
            <h1>this is the Products page</h1>
        </Layout>
        
    );
}

export default Products;
