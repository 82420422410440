import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


const smallnewscard = (props) => {
    return(
      <div className='smallnewscntnt_area'>
      <Grid container className='smallnews_wrap' spacing={1}>
          <Grid item xs={3}>
            <Box className='smallnewsimg_area'><img src={require('../images/politics4.png')} alt="" /></Box>
          </Grid>
          <Grid item xs={9}>
            <div className='smallnewscntnt_wrap'>
              <h5 className='smallnewscntnt_hd'>Maharashtra Assembly: Opposition seeks discussion, relief for farmers</h5>
            </div>
          </Grid>
      </Grid>
 
    </div>
    )
  } 
  export default smallnewscard;