import React from "react";
import Box from '@mui/material/Box';

const ViewButton = () => {
    return (
        <Box>
            <a className="view_btn" href="#" >View more</a>
        </Box>
    )
}

export default ViewButton;