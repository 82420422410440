import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function EconomyCard() {
  return (
    <Card className='trend_card'  sx={{ boxShadow: "none", borderRadius: "0", marginX: "12px" }}>
     
      <img src={require('../images/media_img1.png')} />
      <CardContent className='trend_hd' sx={{paddingX: "0"}}>
        <Typography gutterBottom component="h5">
        Foxconn to use Nvidia chips to build self-driving vehicle platforms
        </Typography>
        <Typography className='trend_cntnt' variant="body2" color="text.secondary">
        Lorem Ipsum is simply dummy text of the printing and typesetting...<a href='#' >Read More</a>
        </Typography>
      </CardContent>
    </Card>
  );
}