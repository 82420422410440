import React from 'react';
import Layout from '../component/Layout';
const Politics = () => {
    return (
        <Layout>
            <h1>this is the Politics page</h1>
        </Layout>

    );
}

export default Politics;
