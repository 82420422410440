import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../App.css';

export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <div className="vidwrap">

        <Slider className="firstvidwrap"
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
        >
          <div className="slide1">
            <img src={require('../images/vid_bnr1.png')} alt="bnr" />
          </div>
          <div className="slide1">
            <img src={require('../images/vid_bnr4.png')} alt="bnr" />
          </div>
          <div className="slide1">
            <img src={require('../images/vid_bnr3.jpg')} alt="bnr" />
          </div>
          <div className="slide1">
            <img src={require('../images/vid_bnr1.png')} alt="bnr" />
          </div>
          <div className="slide1">
            <img src={require('../images/vid_bnr3.jpg')} alt="bnr" />
          </div>
          <div className="slide1">
            <img src={require('../images/vid_bnr4.png')} alt="bnr" />
          </div>

        </Slider>

        <Slider
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={3}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          <div className="slide2">
            <img src={require('../images/vid_bnr1.png')} alt="bnr" />
          </div>
          <div className="slide2">
            <img src={require('../images/vid_bnr4.png')} alt="bnr" />
          </div>
          <div className="slide2">
            <img src={require('../images/vid_bnr3.jpg')} alt="bnr" />
          </div>
          <div className="slide2">
            <img src={require('../images/vid_bnr1.png')} alt="bnr" />
          </div>
          <div className="slide2">
            <img src={require('../images/vid_bnr3.jpg')} alt="bnr" />
          </div>
          <div className="slide2">
            <img src={require('../images/vid_bnr4.png')} alt="bnr" />
          </div>
        </Slider>
      </div>
    );
  }
}

