import React from 'react';
import Layout from '../component/Layout';
const Technology = () => {
    return (
        <Layout>
            <h1>this is the Technology page</h1>
        </Layout>

    );
}

export default Technology;
