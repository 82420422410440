import React from 'react';
import Layout from '../component/Layout';
const Hot = () => {
    return (
        <Layout>
            <h1>this is the Hot page</h1>
        </Layout>
        
    );
}

export default Hot;
