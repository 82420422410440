import React from 'react';
import Layout from '../component/Layout';
const Economy = () => {
    return (
         <Layout>
            <h1>this is the Economy page</h1>
         </Layout>
        
    );
}

export default Economy;
