import React from 'react';
import Layout from '../component/Layout';
const Opinion = () => {
    return (
        <Layout>
            <h1>this is the Opinion page</h1>
        </Layout>

    );
}

export default Opinion;
