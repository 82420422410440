const PoliticsnewsSrc = [
    {
        id: 1,
        imgURL: require('../images/politics1.png'),
        hd: "Bad political leaders and parties looted the state: Arvind Kejriwal",
        cntnt: "Brokerage firm HDFC Securities is bullish on the company's fundamentals, new product launches, growth prospects...",
        link:"#"

    },
    {
        id: 2,
        imgURL: require('../images/politics2.png'),
        hd: "Opposition parties hail SC verdict on Election Commission appointments",
        cntnt: "Congress leader in the Lok Labha Adhir Ranjan Chowdhury terms the apex court move to restrict govt power as “cosmetic”.....",
        link:"#"



    },
    
    
  
]
export default PoliticsnewsSrc;