import React from 'react';
import Layout from '../component/Layout';
const Sports = () => {
    return (
        <Layout>
           <h1>this is the Sports page</h1> 
        </Layout>
        
    );
}

export default Sports;
