const BrknewsSrc = [
    {
        id: 1,
        imgURL: require('../images/pic1.png'),
        hd: "Samsung’s 2023 TV lineup bets everything on picture upgrades and AI tricksTV Tech's NextGen TV Ebook Is Now Available",


    },
    {
        id: 2,
        imgURL: require('../images/pic1.png'),
        hd: "Samsung’s 2023 TV lineup bets everything on picture upgrades and AI tricksTV Tech's NextGen TV Ebook Is Now Available",


    },
    {
        id: 3,
        imgURL: require('../images/pic1.png'),
        hd: "Samsung’s 2023 TV lineup bets everything on picture upgrades and AI tricksTV Tech's NextGen TV Ebook Is Now Available",


    },
    {
        id: 4,
        imgURL: require('../images/pic1.png'),
        hd: "Samsung’s 2023 TV lineup bets everything on picture upgrades and AI tricksTV Tech's NextGen TV Ebook Is Now Available",


    },
    {
        id: 5,
        imgURL: require('../images/pic1.png'),
        hd: "Samsung’s 2023 TV lineup bets everything on picture upgrades and AI tricksTV Tech's NextGen TV Ebook Is Now Available",


    },
    
  
]
export default BrknewsSrc;