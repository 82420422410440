import React from 'react';
import Layout from '../component/Layout';
const Newsletter = () => {
    return (
        <Layout>
            <h1>this is the Newsletter page</h1>
        </Layout>

    );
}

export default Newsletter;
