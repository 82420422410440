import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


const BigNewscard = (props) => {
    return(
      <div className='bigcntnt_area'>
      <Grid className='bigcard_wrap'container spacing={1}>
          <Grid item md={4} sm={6} xs={12}>
            <Box className='bigimg_area'><img src={props.img} alt="" /></Box>
          </Grid>
          <Grid item md={8} sm={6} xs={12} sx={{paddingTop:"0"}}>
            <div className='bigcntnt_wrap'>
              <h5 className='bigcntnt_hd'>{props.hd}</h5>
              <p>{props.cntnt}...<a href={props.link} >Read more</a></p>
            </div>
          </Grid>
      </Grid>
      </div>
      )
      }
      export default BigNewscard;