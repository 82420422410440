import React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Footer = () => {
  return (
    <Paper className="ftr_wrap">
      <Box className="ftr_area">
        <Grid container spacing={2}>
          <Grid item className="ftr_col" lg={3} sm={6} xs={12}>
            <h1>Quick links</h1>
            <ul>
              <li><a>Home</a></li>
              <li><a>Newsletters</a></li>
              <li><a>RSS Feeds</a></li>
              <li><a>About Us</a></li>
            </ul>
          </Grid>
          <Grid item className="ftr_col" lg={3} sm={6} xs={12}>
            <h1>Services</h1>
            <ul>
              <li><a>Faceoff Research</a></li>
              <li><a>Faceoff Services</a></li>
              
            </ul>
          </Grid>
          <Grid item className="ftr_col" lg={3} sm={6} xs={12}>
            <h1><a>Corporate</a></h1>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a>Newsletters</a></li>
              <li><a>RSS Feeds</a></li>
              <li><a>About Us</a></li>
            </ul>
          </Grid>
          <Grid item className="ftr_col" lg={3} sm={6} xs={12} >
            <h1>Follow Us</h1>
            <ul className="follow_wrap">
              <li><img src={require('../images/facebook_icon.png')} />
              <img src={require('../images/insta_icon.png')} />
              <img src={require('../images/twitter_icon.png')} /></li>
              <li className="app_btn"><img src={require('../images/google_play_btn.png')} /></li>
              <li className="app_btn"><img src={require('../images/app_store_btn.png')} /></li>
            </ul>
            <img></img>
            <img></img>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default Footer;
