import React from 'react';
import Layout from '../component/Layout';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CatSrc from '../component/CatSrc';
import AsNavFor from '../component/AsNavFor';
import BrknewsSrc from '../component/BrknewsSrc';
import NewsSlider from '../component/NewsSlider';
import EconomySlider from '../component/EconomySlider';
import BigNewscard from '../component/BigNewsCard';
import PoliticsnewsSrc from '../component/PoliticsnewsSrc';
import Smallnewscard from '../component/SmallnewsCard';
import EconomyCard from '../component/EconomyCard';
import ViewButton from '../component/ViewButton';


const Cathd = (prop) => {
  return (
    <Box>
      <Grid container>
        <Grid className='hd_wrap' md={2} xs={12}>
          <Box className="hd_box" sx={{ backgroundColor: '#E3000F', textAlign: 'center', marginRight: '10px' }}>
            <h2>{prop.hd}</h2></Box>
        </Grid>
        <Grid className="hd_line" md={10} >
          <Box sx={{ width: '99%', height: '4px', backgroundColor: '#E3000F', marginBottom: '0' }}> </Box>
        </Grid>
      </Grid>
    </Box >
  )
}

const CatBox = (props) => {
  return (
    <div className="cat_area">
      <img src={props.img} alt=" " />
      <h5>{props.name}</h5>
    </div>
  )
}

const createcat = (CatSrc) => {
  return (<CatBox
    key={CatSrc.id}
    img={CatSrc.imgURL}
    name={CatSrc.catName}
  />)
}

const createbrknews = (BrknewsSrc) => {
  return (<BreakingNewscard
    key={BrknewsSrc.id}
    img={BrknewsSrc.imgURL}
    hd={BrknewsSrc.hd}
  />)
}

const BreakingNewscard = (props) => {
  return (
    <div>
      <Grid  className='brkcntnt_area' container spacing={1} sx={{marginTop: "2px"}}>
        <Grid lg={4} xs={2} >
          <Box className='brkimg_area'><img src={props.img} alt="" /></Box>
        </Grid>
        <Grid lg={8} xs={10}>
          <div className='brkcntnt_wrap'>
            <h5 className='brkcntnt_hd'>{props.hd}</h5>
            <p>2 hrs ago</p>
          </div>
        </Grid>
      </Grid>
      <hr style={{marginBottom: "0"}}></hr>
    </div>
  )
}


const createpoliticalnews = (PoliticsnewsSrc) => {
  return (<BigNewscard
    key={PoliticsnewsSrc.id}
    img={PoliticsnewsSrc.imgURL}
    hd={PoliticsnewsSrc.hd}
    cntnt={PoliticsnewsSrc.cntnt}
    link={PoliticsnewsSrc.link}
  />)
}


const Home = () => {
  return (
    <Layout>
      
      <Grid container className='breakinews_container_wrap' spacing={0} >
        <Grid lg={8} md={12} >
          <Box className="vidslide_area" sx={{ backgroundColor: "#000000",  }}><AsNavFor /></Box>
        </Grid>
        <Grid className='breakingnews_wrap' lg={4} md={12} sx={{ display: "flex", flexWrap:"wrap", alignContent: "space-between"}}>
          <Box sx={{ backgroundColor: "#000000", padding: "1px", textAlign: "center", width: "100%" }}><h5 className='break_hd'>Breaking News</h5></Box>
          <Box className="breakingnews_area">
            {BrknewsSrc.map(createbrknews)}
          </Box>
        </Grid>
      </Grid>

      <Box><Cathd hd="Opinion Categories" /></Box>
      <div className='cat_wrap'>
        {CatSrc.map(createcat)}
      </div>
      <Box><Cathd hd="Trending News" /></Box>
      <div><NewsSlider /></div>
      <Box><Cathd hd="Economy & Business" /></Box>
      <div><EconomySlider /></div>

      <Box><Cathd hd="Politics" /></Box>
      <div><Grid container spacing={0}>
        <Grid className='news_left_wrap' lg={7} md={12} sm={12} >
          <Box>{PoliticsnewsSrc.map(createpoliticalnews)}</Box>
        </Grid>
        <Grid lg={5} className="right_wrap" sx={{ paddingLeft: '20px', borderLeft: '1px solid #000000' }}>
          <Smallnewscard />     <hr className='linearea' />
          <Smallnewscard />     <hr className='linearea' />
          <Smallnewscard />
        </Grid>
      </Grid>
      </div>
      <Box sx={{textAlign: "center", marginTop: "20px"}} ><ViewButton/></Box>
      

      <Box><Cathd hd="Sports" /></Box>
      <div><Grid container spacing={0}>
        <Grid className='news_left_wrap' lg={7} md={12} sm={12} >
          <Box className="left_wrap" sx={{  }}>{PoliticsnewsSrc.map(createpoliticalnews)}</Box>
        </Grid>
        <Grid lg={5} md={12} className="right_wrap" sx={{ paddingLeft: '20px', borderLeft: '1px solid #000000' }}>
          <Smallnewscard />     <hr className='linearea' />
          <Smallnewscard />     <hr className='linearea' />
          <Smallnewscard />
        </Grid>
      </Grid>
      </div>
      <Box sx={{textAlign: "center", marginTop: "20px"}} ><ViewButton/></Box>


      <Box className="hd_box" sx={{ backgroundColor: '#E3000F', textAlign: 'center',  marginTop: "30px"  }}>
        <Grid container spacing={0} className="ent_hd" sx={{ background: "#E3000F", margin: "0" }}>
          <Grid className="ent_hd_wrap" lg={2} md={12} sm={12} >
            <h5>Entertainment</h5>
          </Grid>
          <Grid className='right_wrap' md={10} sm={1}>
          </Grid>
        </Grid>
      </Box>

      <Box className="ent_box" sx={{  background: "#E8E8E8" }}>
        <Grid className='ent_wrap' container spacing={0} >
          <Grid className='news_left_wrap' lg={7} md={12} sm={12}>
            <Box className="left_wrap" sx={{ paddingRight: "15px" }}>
            <EconomyCard />
            <EconomyCard />
            </Box>
          </Grid>
          <Grid className="right_wrap" lg={5}  sx={{ paddingLeft: '20px', borderLeft: '1px solid #000000' }}>
            <Smallnewscard /> <hr className='linearea' />
            <Smallnewscard /> <hr className='linearea' />
            <Smallnewscard />
          </Grid>
        </Grid>
        <Box sx={{textAlign: "center", marginTop: "20px"}} ><ViewButton/></Box>
      </Box>

      <Box><Cathd hd="What's Hot" /></Box>
      <div><Grid container spacing={0}>
        <Grid lg={7} md={12}>
          <Box className="left_wrap" sx={{  }}>{PoliticsnewsSrc.map(createpoliticalnews)}</Box>
        </Grid>
        <Grid lg={5}  className="right_wrap" sx={{ paddingLeft: '20px', borderLeft: '1px solid #000000' }}>
          <Smallnewscard />     <hr className='linearea' />
          <Smallnewscard />     <hr className='linearea' />
          <Smallnewscard />
        </Grid>
      </Grid>
      <Box sx={{textAlign: "center", marginTop: "20px", marginBottom:"30px"}} ><ViewButton/></Box>
      </div>
     

    </Layout>

  );
}

export default Home;
