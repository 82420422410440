import React from 'react';
import Layout from '../component/Layout';
const Community = () => {
    return (
        <Layout>
             <h1>this is the Community page</h1>
        </Layout>
       
    );
}

export default Community;
