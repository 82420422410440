
import * as React from 'react';
import { Link } from "@mui/material";
import Header from "./component/Header";
import Footer from "./component/Footer";
//import MyVideoSlider from './component/MyVideoSlider';
//import BreakingNews from './component/BreakingNews';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
//import home from './images/home.png';
//import Grid from '@mui/material/Grid';
//import Navbar from './component/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'
import Products from './pages/Products';
import Newsletter from './pages/Newsletter';
import Opinion from './pages/Opinion';
import Community from './pages/Community';
import Technology from './pages/Technology';
import Economy from './pages/Economy';
import Politics from './pages/Politics';
import Sports from './pages/Sports';
import Entertainment from './pages/Entertainment';
import Hot from './pages/Hot';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '2',
  borderRadius: '12px',
  borderColor: '#000000',
  backgroundColor: '#f0f0f0',
  '&:hover': {
    backgroundColor: '#eeeeee',
  },

  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '60vw',
      height: '15px',
    },
  },
}));

const menuId = 'primary-search-account-menu';



function App() {

  const Toplink = styled(Link)({
    paddingRight: 2,
    underline: "none",
    color: "#000000",
  });


  return (
    <>
     
      <div>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route exact path='/Home' element={<Home />} />
            <Route exact path='/products' element={<Products />} />
            <Route exact path='/newsletter' element={<Newsletter />} />
            <Route exact path='/opinion' element={<Opinion />} />
            <Route exact path='/community' element={<Community />} />
            <Route exact path='/technology' element={<Technology />} />
            <Route exact path='/economy' element={<Economy />} />
            <Route exact path='/politics' element={<Politics />} />
            <Route exact path='/sports' element={<Sports />} />
            <Route exact path='/entertainment' element={<Entertainment />} />
            <Route exact path='/hot' element={<Hot />} />
          </Routes>
        </BrowserRouter>
      </div>
   

    </>
  );
}

export default App;
