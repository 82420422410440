import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../App.css';

export default class HeadlineSlider extends Component {
    render() {
        const settings = {
            arrows: false,
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            speed: 20000,
            autoplaySpeed: 120,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="headlineSliderwrap">

                <Slider {...settings}>
                    <div className="headlinearea">
                        Can India Challange the Internet’s big MAC
                    </div>
                    <div className="headlinearea">
                        Can India Challange the Internet’s big MAC
                    </div>
                    <div className="headlinearea">
                        Can India Challange the Internet’s big MAC
                    </div>
                    <div className="headlinearea">
                        Can India Challange the Internet’s big MAC
                    </div>
                    <div className="headlinearea">
                        Can India Challange the Internet’s big MAC
                    </div>
                </Slider>
            </div>
        );
    }
}
